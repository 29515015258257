var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "dashboard" },
    [
      _c("h2", [_vm._v("Trademark Monitoring")]),
      _c("monitoring-tab-bar"),
      _c("div", { staticClass: "content" }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }